

.total-amount-container {
    position: fixed;
    /* Fix position to keep it on the right */
    top: 80px;
    /* Adjust as needed to place it below the header or products */
    right: 100px;
    /* Adjust as needed for padding */
    background-color: white;
    /* White background to differentiate from the rest */
    padding: 30px;
    /* Padding for better spacing */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Optional shadow for depth */
    z-index: 1000;
    /* Ensure it stays above other content */
}

@media (max-width: 600px) {
    .total-amount-container {
        position: static;
        /* Make it static on small screens */
        top: auto;
        right: auto;
        width: 100%;
        /* Full width on small screens */
    }
}