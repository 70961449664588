.btn {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    color: #333;
    padding: 8px 16px;
    margin: 0 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #ddd;
}

.btn-active {
    background-color: #008080;
    color: #fff;
    border-color: #008080;
}

.btn-active:hover {
    background-color: #65b8b8;
}