@use './abstracts/' as *;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

body {
    font-family: $fontFamily;
    color: $txtColor;
    font-size: $fontSizeNormal;
    line-height: 1.5;
    background-color: $bodyBg;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: unset;
}

img {
    width: 100%;
}

ul,
li {
    list-style-type: none;
}

.mb {
    margin-bottom: $spacing;

    @include tablet {
        margin-bottom: $mobileSpacing;
    }
}

.title {
    font-size: $fontSizeMedium;
    font-weight: 700;
}

.txt-success {
    color: $green;
}

.txt-danger {
    color: $red;
}

table::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Edge */
}

.back-button {
    display: inline-block;
    align-items: center;
    font-size: 30px;
    color: blue;
    cursor: pointer;
    text-decoration: none;
    /* To remove the underline effect */
    transition: background-color 0.3s, transform 0.2s;
}

.back-button:hover {
    /* Change color on hover */
    transform: scale(1.1);
    /* Slight scaling effect on hover */
}

.arrow {
    font-size: 30px;
    /* Adjust size for the arrow */
}