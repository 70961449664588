/* Banner.css */

.carousel {
    margin-top: 50px;
    position: relative;
    width: 100%;
    max-width: 1500px;
    height: 300px;
    margin: auto;
    overflow: hidden;
    border-radius: 8px;
    padding: 0 15px;
}

.carousel-inner {
    display: flex;
    height: 100%;
    gap: 5px;
    animation: scroll 30s linear infinite;
    /* Adjust timing for speed */
}

.carousel-item {
    min-width: 42%;
    height: 100%;
}

.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    margin: 2px;
}

/* Keyframes for continuous scroll */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
    .carousel-item {
        min-width: 70%;
    }
}

@media (max-width: 768px) {
    .carousel-item {
        min-width: 100%;
    }

    .carousel {
        height: 250px;
    }
}

@media (max-width: 480px) {
    .carousel {
        height: 200px;
    }
}
/* Popup styling */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Transparent overlay for focus */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    position: relative;
    padding: 0;
    /* Remove padding */
    background: none;
    /* Remove background */
    border-radius: 8px;
    text-align: center;
}

.popup-image {
    width: 300px;
    /* Smaller image size */
    height: auto;
    border-radius: 8px;
}

.popup-close {
    position: absolute;
    top: -10px;
    right: -10px;
    background: white;
    border: none;
    border-radius: 50%;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 10px;
}